import React from 'react';
import { Portal } from 'react-portal';
import coffee from '../assets/coffee.png';
import canal from '../assets/canal.png';
import iceCream from '../assets/ice-cream.png';
import streetArt from '../assets/street-art.png';
import canal02 from '../assets/canal-02.png';
import canal03 from '../assets/canal-03.png';
import coffee02 from '../assets/coffee-02.png';
import coffee03 from '../assets/coffee-03.png';
import iceCream02 from '../assets/ice-cream-02.png';
import iceCream03 from '../assets/ice-cream-03.png';
import streetArt02 from '../assets/street-art-02.png';
import streetArt03 from '../assets/street-art-03.png';

let picsList = [
  coffee,
  canal,
  iceCream,
  streetArt,
  canal02,
  canal03,
  iceCream02,
  iceCream03,
  coffee02,
  coffee03,
  streetArt02,
  streetArt03,
];

function YellowPics({ isOpen, onClose }) {
  let pic = React.useMemo(
    () => picsList[Math.floor(Math.random() * picsList.length)],
    [isOpen]
  );

  return isOpen ? (
    <Portal>
      <div className="modal is-active custom-modal">
        <div className="modal-background" />
        <div className="modal-content">
          <p className="image">
            <img src={pic} alt="coffee" loading="lazy" />
          </p>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={onClose}
        />
      </div>
      <style jsx="true">{`
        .modal-content {
          max-height: unset;
          overflow-x: hidden;
          overflow-y: scroll;
        }
      `}</style>
    </Portal>
  ) : null;
}

export default YellowPics;
