import React from 'react';
import Helmet from 'react-helmet';
import BackgroundImage from '../assets/background.png';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../pages/app.scss';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

/* eslint-disable max-len */
export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <script defer src="https://kit.fontawesome.com/766e0d2556.js" />
      </Helmet>
      <div>{children}</div>
      <style global="true" jsx="true">{`
        body {
          padding: 0;
          margin: 0;
        }

        html {
          background: url(${BackgroundImage}) no-repeat center center fixed;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
        }

        .mapboxgl-user-location-dot::before {
          background-color: #f9eb53;
        }

        .mapboxgl-user-location-dot::after {
          background-color: #f9eb53;
        }

        .mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23F9EB53'%3E %3Cpath d='M10 4C9 4 9 5 9 5L9 5.1A5 5 0 0 0 5.1 9L5 9C5 9 4 9 4 10 4 11 5 11 5 11L5.1 11A5 5 0 0 0 9 14.9L9 15C9 15 9 16 10 16 11 16 11 15 11 15L11 14.9A5 5 0 0 0 14.9 11L15 11C15 11 16 11 16 10 16 9 15 9 15 9L14.9 9A5 5 0 0 0 11 5.1L11 5C11 5 11 4 10 4zM10 6.5A3.5 3.5 0 0 1 13.5 10 3.5 3.5 0 0 1 10 13.5 3.5 3.5 0 0 1 6.5 10 3.5 3.5 0 0 1 10 6.5zM10 8.3A1.8 1.8 0 0 0 8.3 10 1.8 1.8 0 0 0 10 11.8 1.8 1.8 0 0 0 11.8 10 1.8 1.8 0 0 0 10 8.3z'/%3E %3C/svg%3E");
        }

        .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
          display: none;
        }

        .mapboxgl-ctrl-attrib.mapboxgl-compact {
          display: none;
        }

        .mapboxgl-ctrl-scale {
          border-width: medium 0.75px 0.75px;
        }
      `}</style>
    </React.Fragment>
  );
}

/* eslint-enable max-len */
