import { socket } from './';
import uuid from 'uuid/v4';

export const updateClientCoordinates = coordinates => {
  // generate unique client id
  let id = window.localStorage.getItem('lost_user_id');

  console.log('updating coords');

  if (!id) {
    id = uuid();
    window.localStorage.setItem('lost_user_id', id);
  }

  socket.emit('updateClientCoordinates', { ...coordinates, id });
};
