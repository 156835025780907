import React, { useEffect } from 'react';
import MapGL, {
  GeolocateControl,
  CanvasOverlay,
  Marker,
  NavigationControl,
} from 'react-map-gl';
import { ClientSocket } from 'use-socketio';
import { getDistance } from 'geolib';
import { geolocateStyle } from '../utils/styles';
import { initCoords, MAPBOX_TOKEN } from '../utils';
import ClientMarkers from './client-markers';
import { updateClientCoordinates } from '../utils/socket.io/emit';
import PolylineOverlay from '../components/polyline-overlay';
import useLocalStorage from '../hooks/use-local-storage';
import compassIcon from '../compass.png';

const initViewport = {
  ...initCoords,
  zoom: 14,
};

class CustomOverlay extends React.PureComponent {
  _redraw = ({ width, height, ctx, isDragging, project, unproject }) => {
    const {
      points,
      color = this.props.color || '#4a4a4a',
      lineWidth = 4,
      renderWhileDragging = true,
      opacity = 0.3,
    } = this.props;

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    ctx.globalCompositeOperation = 'destination-out';

    if ((renderWhileDragging || !isDragging) && points) {
      ctx.lineWidth = 30; //lineWidth;
      ctx.strokeStyle = 'rgba(0,0,0,1)'; //color;
      ctx.globalAlpha = opacity;
      ctx.beginPath();
      points.forEach(point => {
        const pixel = project([point[0], point[1]]);
        ctx.lineTo(pixel[0], pixel[1]);
      });
      ctx.stroke();
    }
  };

  render() {
    return <CanvasOverlay redraw={this._redraw} />;
  }
}

function Route({ trip: { dest, start }, stage, onStep }) {
  let mapRef = React.useRef();
  let geolocateControlRef = React.useRef();

  let [viewport, setViewport] = React.useState(initViewport);
  let [lostPath, setLostPath] = useLocalStorage('lost_path', []);
  let userPath = React.useMemo(() => {
    if (lostPath) {
      return lostPath.map(each => [each.longitude, each.latitude]);
    } else {
      return [];
    }
  }, [lostPath]);

  let updatePath = ({ latitude, longitude }) => {
    let newPath = [...lostPath];
    if (newPath.length > 0) {
      let prevCoord = newPath[newPath.length - 1];
      // avoid double update
      if (
        prevCoord.latitude !== latitude ||
        prevCoord.longitude !== longitude
      ) {
        newPath.push({ longitude, latitude });
        let steps = getDistance(prevCoord, { longitude, latitude }) / 0.762;
        onStep(Math.ceil(steps)); // update step estimates
      }
    } else {
      newPath.push({ longitude, latitude });
    }
    setLostPath(newPath);
  };

  let hanldeViewportChange = vp => {
    console.log('[gps] coordinates update');

    // do not presist new coords when not on trip
    if (stage !== 'START_TRIP') return;

    // update path at local storage and step estimates
    updatePath(vp);
    updateClientCoordinates({
      longitude: vp.longitude,
      latitude: vp.latitude,
    });
  };

  useEffect(() => {
    // start tracking user location once map is loaded
    let map = mapRef.getMap();
    map.on('load', function() {
      document.querySelector('.mapboxgl-ctrl-geolocate').click();
    });
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setViewport({
        ...viewport,
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
      });
    });
  }, []);

  useEffect(() => {
    console.info('[appcues] triggering appcues tutorial');
    window.Appcues.page();
    window.Appcues.show('-LmrsDTS42-Ct6XHq9pg');
    window.Appcues.identify(
      window.localStorage.getItem('62627bc0-f33f-4367-822b-46191526dbc6')
    );
  }, [stage]);

  return (
    <MapGL
      {...viewport}
      width="100%"
      height="90%"
      mapStyle="mapbox://styles/michael-lin/cjzioohos47aj1cns2waj81l2"
      mapboxApiAccessToken={MAPBOX_TOKEN}
      onViewportChange={vp => setViewport({ ...viewport, ...vp })}
      ref={ele => {
        mapRef = ele;
      }}
    >
      {/* {process.env.REACT_APP_APP_ENV === 'production' ? ( */}
      <CustomOverlay points={userPath} color="#4AA8D1" />
      {/* ) : null} */}
      <Marker
        latitude={dest[1]}
        longitude={dest[0]}
        offsetLeft={-12}
        offsetTop={-10}
      >
        <span className="icon" style={{ color: '#DB1D1E' }}>
          <i className="fas fa-2x fa-map-marker-alt" />
        </span>
      </Marker>
      <ClientSocket url={process.env.REACT_APP_SOCKET_IO_URL}>
        <ClientMarkers />
      </ClientSocket>
      {/* <PolylineOverlay points={userPath} color="#4AA8D1" /> */}
      <div style={{ position: 'absolute', right: 0, margin: 10 }}>
        <a className="button is-small">
          <span className="icon is-small" style={{ backgroundColor: 'white' }}>
            <figure className="image is-16x16">
              <img src={compassIcon} />
            </figure>
          </span>
        </a>
      </div>
      <GeolocateControl
        style={geolocateStyle}
        onViewportChange={hanldeViewportChange}
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation={true}
        ref={geolocateControlRef}
      />
    </MapGL>
  );
}

export default Route;
