import React from 'react';
import { Link } from '@reach/router';
import logo from '../assets/logo.png';

function Header({ size = 'small' }) {
  return (
    <div className="hero-head">
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logo} alt="logo" height="24px" />
          </Link>
        </div>
      </nav>
      <style jsx="true">{`
        .navbar img {
          max-height: ${size === 'small'
            ? '3.25rem'
            : size === 'large'
            ? '6.25rem'
            : '4.25rem'};
        }
      `}</style>
    </div>
  );
}

export default Header;
