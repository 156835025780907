import React from 'react';
import { Portal } from 'react-portal';

function Notification({ isOpen = true, onClose }) {
  let [isVisible, setIsVisible] = React.useState(isOpen);

  React.useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  return isVisible ? (
    <Portal>
      <div className="modal is-active custom-modal">
        <div className="modal-background" />
        <div className="modal-content">
          <div className="notification is-danger">
            <button className="delete" onClick={onClose} />
            <strong>Fail</strong> to retrieve current location, please manualy
            type in your address.
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .notification {
          margin: 0 20px;
        }
      `}</style>
    </Portal>
  ) : null;
}

export default Notification;
