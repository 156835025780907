import React from 'react';
import 'firebase/firestore';
import 'firebase/performance';
import HeroHeader from '../components/header';
import Spinner from '../components/spinner';
import {
  useFirebaseApp,
  SuspenseWithPerf,
  useFirestoreCollection,
} from 'reactfire';
import Layout from '../components/layout';

function TripsList() {
  let firebaseApp = useFirebaseApp();
  let tripRef = firebaseApp.firestore().collection('Trip');
  let trips = useFirestoreCollection(tripRef);

  return (
    <React.Fragment>
      {trips.docs.map(each => {
        let { imageUrl, origin, destination } = each.data();
        return (
          <div
            key={each.id}
            className="card"
            style={{
              backgroundImage: `url('${imageUrl}')`,
            }}
            onClick={() => window.open(imageUrl, '_blank')}
          >
            <div className="card-content">
              <div>{origin.name}</div>
              <div>-</div>
              <div>{destination.name}</div>
            </div>
          </div>
        );
      })}
      <style jsx="true">{`
        .card {
          font-family: Prestige Elite Std;
          margin: 24px 0;
          height: 124px;
          overflow: hidden;
        }
      `}</style>
    </React.Fragment>
  );
}

function SharePage() {
  return (
    <Layout>
      <section className="hero custom-is-fullheight r-page">
        <HeroHeader />
        <div className="hero-body has-text-centered">
          <SuspenseWithPerf fallback={<Spinner />}>
            <TripsList />
          </SuspenseWithPerf>
        </div>
      </section>
    </Layout>
  );
}

export default SharePage;
