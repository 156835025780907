import React from 'react';
import { ClientSocket } from 'use-socketio';
import { LngLatBounds, CanvasOverlay } from 'mapbox-gl';
import { getDistance } from 'geolib';
import WebMercatorViewport from 'viewport-mercator-project';
import MapGL, { GeolocateControl, Marker } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import uuid from 'uuid/v4';
import ClientMarkers from '../components/client-markers';
import Notification from '../components/notification';
// import Layout from '../components/layout';
import PolylineOverlay from '../components/polyline-overlay';
import * as API from '../utils/api';
import { initCoords } from '../utils';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

// IMPORTANT
// let coords = [long, lat];
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

const initViewport = {
  ...initCoords,
  zoom: 14,
};

const geolocateStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: 10,
};

class StartPage extends React.Component {
  state = {
    viewport: initViewport,
    startCoords: [],
    destCoords: [],
    route: [],
    currentPath: [],
    duration: null,
    distance: null,
    isNotificationVisible: false,
  };

  mapRef = React.createRef();
  startGeocoderRef = React.createRef();
  destGeocoderRef = React.createRef();
  startGeocoderContainerRef = React.createRef();
  destGeocoderContainerRef = React.createRef();
  geolocateControlRef = React.createRef();

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      position => {
        console.log(position);
        this.setState({
          startCoords: [position.coords.longitude, position.coords.latitude],
        });
      },
      error => {
        this.setState({ isNotificationVisible: true });
      }
    );
  }

  getBoundingBox = coordinates => {
    let bounds = coordinates.reduce(function(bounds, coord) {
      return bounds.extend(coord);
    }, new LngLatBounds(coordinates[0], coordinates[0]));
    let bbox = bounds.toArray();
    return new WebMercatorViewport(this.state.viewport).fitBounds(
      [bbox[0], bbox[1]],
      {
        padding: 20,
        offset: [0, 0],
      }
    );
  };

  setViewport = viewport => {
    this.setState({ viewport });
  };

  handleViewportChange = vp => {
    vp.zoom = this.state.viewport.zoom; // keep zoom level untouched
    this.setState({ viewport: vp });
  };

  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };
    return this.setViewport({
      ...viewport,
      ...geocoderDefaultOverrides,
      zoom: 14,
    });
  };

  handleStartGeocoderResult = async event => {
    let { destCoords, viewport: oldViewport } = this.state;
    // let { stage, setStage } = this.props;

    let route, duration, distance;
    let viewport = oldViewport;

    if (destCoords.length === 2) {
      let resp = await API.Mapbox.getDirection([
        event.result.center,
        destCoords,
      ]);
      route = resp.route;
      duration = resp.duration;
      distance = resp.distance;
      viewport = this.getBoundingBox(route);
    }

    if (destCoords.length === 2) {
      // setStage('PENDING_TRIP');
      this.setState({
        route,
        duration,
        distance,
        startCoords: event.result.center,
        viewport,
      });
    } else {
      // setStage('SET_DEST');
      this.setState({
        route,
        startCoords: event.result.center,
        viewport,
      });
    }
  };

  handleDestGeocoderResult = async event => {
    let { startCoords } = this.state;
    console.log(startCoords);
    console.log(event.result.center);
    let { route, duration, distance } = await API.Mapbox.getDirection([
      startCoords,
      event.result.center,
    ]);
    let viewport = this.getBoundingBox(route);
    this.props.setStage('PENDING_TRIP');
    this.setState({
      route,
      viewport,
      distance,
      duration,
      destCoords: event.result.center,
    });
  };

  handleStartTrip = async () => {
    let { startCoords, destCoords, duration, distance, route } = this.state;
    window.localStorage.setItem('lost_trip_id', uuid());
    window.localStorage.setItem('lost_distance_plan', distance);
    window.localStorage.setItem('lost_step_plan', Math.ceil(distance / 0.762));
    window.localStorage.setItem('lost_duration_plan', duration);
    window.localStorage.setItem('lost_plan', JSON.stringify(route));
    window.localStorage.setItem(
      'lost_start_coords',
      JSON.stringify(startCoords)
    );
    window.localStorage.setItem('lost_dest_coords', JSON.stringify(destCoords));
    // window.localStorage.setItem('lost_plan', )
    this.props.setStepCount(-Math.ceil(distance / 0.762));
    window.localStorage.setItem('lost_start', new Date());
    window.localStorage.removeItem('lost_end');
    window.localStorage.setItem('lost_duration', 0);
    this.props.setTripDetail({
      start: startCoords,
      dest: destCoords,
      duration,
    });
    this.props.setStage('START_TRIP');
    this.setState({ route: [] });
  };

  render() {
    let {
      viewport,
      destCoords,
      route,
      currentPath,
      isNotificationVisible,
    } = this.state;
    let { stage, setStage } = this.props;

    return (
      <React.Fragment>
        <Notification isOpen={isNotificationVisible} />
        <MapGL
          {...viewport}
          width="100%"
          height="90%"
          mapStyle="mapbox://styles/michael-lin/cjzioohos47aj1cns2waj81l2"
          onViewportChange={vp => this.setViewport({ ...viewport, ...vp })}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          ref={this.mapRef}
        >
          <div
            className={`column is-centered custom-geocoder ${
              stage === 'START_TRIP' ? 'is-hidden' : null
            }`}
            ref={this.startGeocoderContainerRef}
            onClick={() => {
              setStage('SET_START');
            }}
            onBlur={() => {
              setTimeout(() => {
                if (destCoords.length === 2) {
                  setStage('PENDING_TRIP');
                } else {
                  setStage('SET_DEST');
                }
              }, 200);
            }}
          />
          <div
            className={`column is-centered custom-geocoder ${
              stage === 'SET_START' || stage === 'START_TRIP'
                ? 'is-hidden'
                : null
            }`}
            ref={this.destGeocoderContainerRef}
          />
          {destCoords.length > 0 ? (
            <Marker
              latitude={destCoords[1]}
              longitude={destCoords[0]}
              offsetLeft={-12}
              offsetTop={-10}
            >
              <span className="icon" style={{ color: '#DB1D1E' }}>
                <i className="fas fa-2x fa-map-marker-alt" />
              </span>
            </Marker>
          ) : null}
          <ClientSocket url={process.env.REACT_APP_SOCKET_IO_URL}>
            <ClientMarkers />
          </ClientSocket>
          <PolylineOverlay points={currentPath} color="#4AA8D1" />
          {/* <PolylineOverlay points={route} color="#4AA8D1" /> */}
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            onViewportChange={this.handleViewportChange}
            ref={this.geolocateControlRef}
          />
          <Geocoder
            ref={this.startGeocoderRef}
            containerRef={this.startGeocoderContainerRef}
            mapRef={this.mapRef}
            onResult={this.handleStartGeocoderResult}
            onViewportChange={this.handleGeocoderViewportChange}
            placeholder="Current location"
            mapboxApiAccessToken={MAPBOX_TOKEN}
            countries="NL,CA"
          />
          <Geocoder
            className="custom-geocoder"
            ref={this.destGeocoderRef}
            containerRef={this.destGeocoderContainerRef}
            mapRef={this.mapRef}
            onResult={this.handleDestGeocoderResult}
            onViewportChange={this.handleGeocoderViewportChange}
            placeholder="Find a destination"
            mapboxApiAccessToken={MAPBOX_TOKEN}
            countries="NL,CA"
          />
        </MapGL>
      </React.Fragment>
    );
  }
}

export default StartPage;
