import React, { PureComponent } from 'react';
import { CanvasOverlay } from 'react-map-gl';

class PolylineOverlay extends PureComponent {
  _redraw = ({ width, height, ctx, isDragging, project, unproject }) => {
    const {
      points,
      color = this.props.color || '#4a4a4a',
      lineWidth = 4,
      renderWhileDragging = true,
      opacity = 0.3,
    } = this.props;
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'lighter';

    if ((renderWhileDragging || !isDragging) && points) {
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = color;
      ctx.globalAlpha = opacity;
      ctx.beginPath();
      points.forEach(point => {
        const pixel = project([point[0], point[1]]);
        ctx.lineTo(pixel[0], pixel[1]);
      });
      ctx.stroke();
    }
  };

  render() {
    return <CanvasOverlay redraw={this._redraw} />;
  }
}

export default PolylineOverlay;
