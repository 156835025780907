import React from 'react';
import { Router } from '@reach/router';
import HomePage from './pages/index';
import StartPage from './pages/start';
import ResultPage from './pages/result';
import SharePage from './pages/share';
import './assets/PrestigeEliteStd.otf';
import 'typeface-source-serif-pro';

function App() {
  return (
    <Router>
      <HomePage path="/" />
      <StartPage path="start" />
      <ResultPage path="result" />
      <SharePage path="share" />
    </Router>
  );
}

export default App;
