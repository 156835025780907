import React from 'react';
import { Link } from '@reach/router';
import HeroHeader from '../components/header';
import Layout from '../components/layout';
import introTextImage from '../assets/intro-text.png';

function HomePage() {
  return (
    <Layout>
      <section className="hero custom-is-fullheight">
        <HeroHeader size="large" />
        <div className="hero-body">
          <div className="container has-text-centered has-text-white">
            <div className="columns is-centered" style={{ paddingTop: '24px' }}>
              <div className="column is-three-quarters">
                <p className="image">
                  <img src={introTextImage} />
                </p>
                <Link to="/start" className="start-btn button">
                  Start
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot" />
      </section>
      <style jsx="true">{`
        .hero-body {
          padding: 0.5rem 1.5rem 1.5rem;
        }
        .start-btn {
          margin-top: 48px;
          background: rgba(255, 255, 255, 1);
          border-radius: 19px;
          -webkit-border-radius: 19px;
          font-family: Source Serif Variable;
          font-weight: bold;
          font-size: 2rem;
          line-height: 66px;
          letter-spacing: 5.42px;
          color: rgb(91, 160, 181);
          opacity: 0.9;
        }

        .intro-text {
          display: inline;
          margin-bottom: 12px;
          background-color: #91c7e1;
        }
      `}</style>
    </Layout>
  );
}

export default HomePage;
