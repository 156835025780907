import React, { useState } from 'react';
import queryString from 'query-string';
import humanizeDuration from 'humanize-duration';
import { navigate } from '@reach/router';
import * as API from '../utils/api';
import 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';
import Layout from '../components/layout';

function ResultPage({ location: { search } }) {
  let {
    url,
    duration,
    durationMore,
    stepsMore,
    airMore,
    origin,
    destination,
  } = queryString.parse(search);
  let firebaseApp = useFirebaseApp();
  let [isLoading, setIsLoading] = useState(false);

  let handleShareTrip = async () => {
    navigate('/share');
  };

  let sendTripResult = async () => {
    setIsLoading(true);

    let tripRef = firebaseApp.firestore().collection('Trip');

    let originCoords = {
      longitude: parseFloat(origin[0]),
      latitude: parseFloat(origin[1]),
    };
    let destCoords = {
      longitude: parseFloat(destination[0]),
      latitude: parseFloat(destination[1]),
    };

    let [originAddr, destAddr] = await Promise.all([
      API.Mapbox.getStreetName([...Object.values(originCoords)]),
      API.Mapbox.getStreetName([...Object.values(destCoords)]),
    ]);

    let payload = {
      imageUrl: url,
      origin: {
        ...originCoords,
        address: originAddr.placeName,
        name: originAddr.text,
      },
      destination: {
        ...destCoords,
        address: destAddr.placeName,
        name: destAddr.text,
      },
      duration,
      createdAt: new Date(),
    };
    await tripRef.doc(window.localStorage.getItem('lost_trip_id')).set(payload);

    setIsLoading(false);
  };

  React.useEffect(() => {
    sendTripResult();
  }, [search]);

  return (
    <Layout>
      <section className="hero custom-is-fullheight r-page">
        <div className="hero-body has-text-centered">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title is-centered is-size-4">
                CONGRATULATIONS
              </p>
            </header>
            <div className="card-content">
              <p className="is-size-5">
                You have successfully freed yourself from efficiency!
              </p>
            </div>
            <div className="card-image">
              <figure className="image is-2by3">
                <img src={url} />
              </figure>
            </div>
            <div className="card-content is-size-5">
              <div>In this trip you gained:</div>
              <div>{humanizeDuration(duration * 1000, { round: true })} </div>
              <div>{durationMore}% more time</div>
              <div>{stepsMore} more steps</div>
              <div>{airMore} more litre of fresh air</div>
              <button
                className={`share-btn button ${
                  isLoading ? 'is-loading' : null
                }`}
                onClick={handleShareTrip}
              >
                Share with other users
              </button>
            </div>
          </div>
        </div>
      </section>
      <style jsx="true">{`
        .hero-body {
          font-family: Prestige Elite Std;
        }

        .card {
          background: rgba(255, 255, 255, 0.9);
          border-radius: 48px;
        }

        .card-header {
          box-shadow: unset;
        }

        .card-header-title {
          color: #6994ad;
        }

        .card-image {
          padding: 0 30px 0 30px;
        }

        .share-btn {
          background: #5ba0b5;
          background: rgba(91, 160, 181, 1);
          opacity: 0.8;
          border-radius: 32px;
          font-family: Source Serif Variable;
          color: #ffffff;
          color: rgb(255, 255, 255);
          text-outline: #ffffff;
          text-outline: rgb(255, 255, 255);
          opacity: 0.9;
        }
      `}</style>
    </Layout>
  );
}

export default ResultPage;
