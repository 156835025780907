import { useSocket } from 'use-socketio';
import React, { useState, useEffect } from 'react';
import Marker from './pulsate-marker';

const ClientMarkers = () => {
  let [clients, setClients] = useState([]);

  let socket = useSocket('clientCoordinates', newClients => {
    console.log('[socket.io] received update');
    setClients(newClients);
  });

  useEffect(() => {
    socket.emit('addClientSession', {
      id: window.localStorage.getItem('lost_user_id'),
    });

    socket.on('requestRegisterSession', () => {
      let id = window.localStorage.getItem('lost_user_id');
      if (id) {
        socket.emit('addClientSession', {
          id,
        });
      }
    });
  }, [socket]);

  return (
    <React.Fragment>
      {clients.length
        ? clients.map(client => (
            <Marker
              key={client.id}
              latitude={client.latitude}
              longitude={client.longitude}
              offsetLeft={-12}
              offsetTop={-10}
            />
          ))
        : null}
    </React.Fragment>
  );
};

export default ClientMarkers;
