import React from 'react';
import { Marker } from 'react-map-gl';

function PulsateMarker({ latitude, longitude, offsetLeft, offsetTop, color }) {
  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
    >
      <div className="pulsate-dot" />
      <style jsx="true">{`
        .pulsate-dot {
          position: absolute;
          top: 0;
          left: 0;
          will-change: transform;
          background-color: ${color || '#4ba8d2'};
          width: 15px;
          height: 15px;
          border-radius: 50%;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
          opacity: 0.5;
        }

        .pulsate-dot::before {
          background-color: ${color || '#4ba8d2'};
          content: '';
          width: 15px;
          height: 15px;
          border-radius: 50%;
          position: absolute;
          -webkit-animation: pulsate-dot-pulse 2s infinite;
          -moz-animation: pulsate-dot-pulse 2s infinite;
          -ms-animation: pulsate-dot-pulse 2s infinite;
          animation: pulsate-dot-pulse 2s infinite;
        }

        .pulsate-dot::after {
          border-radius: 50%;
          border: 2px solid #fff;
          content: '';
          height: 19px;
          left: -2px;
          position: absolute;
          top: -2px;
          width: 19px;
          box-sizing: border-box;
        }

        @-webkit-keyframes pulsate-dot-pulse {
          0% {
            -webkit-transform: scale(1);
            opacity: 1;
          }
          70% {
            -webkit-transform: scale(3);
            opacity: 0;
          }
          100% {
            -webkit-transform: scale(1);
            opacity: 0;
          }
        }

        @-ms-keyframes pulsate-dot-pulse {
          0% {
            -ms-transform: scale(1);
            opacity: 1;
          }
          70% {
            -ms-transform: scale(3);
            opacity: 0;
          }
          100% {
            -ms-transform: scale(1);
            opacity: 0;
          }
        }

        @keyframes pulsate-dot-pulse {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          70% {
            transform: scale(3);
            opacity: 0;
          }
          100% {
            transform: scale(1);
            opacity: 0;
          }
        }
      `}</style>
    </Marker>
  );
}

export default PulsateMarker;
