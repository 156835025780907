import MapboxClient from '@mapbox/mapbox-sdk';
import Directions from '@mapbox/mapbox-sdk/services/directions';
import Static from '@mapbox/mapbox-sdk/services/static';
import Geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import fetch from 'isomorphic-unfetch';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;
// const API_ROOT =
//   process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_API_URL
//     : 'http://localhost:3000';
let { host, protocol } = window.location;
let API_ROOT = `${protocol}//${host}`;

const mapboxClient = MapboxClient({ accessToken: MAPBOX_TOKEN });
const directions = Directions(mapboxClient);
const staticClient = Static(mapboxClient);
let geocodingClient = Geocoding(mapboxClient);

const Mapbox = {
  getDirection: coords => {
    let waypoints = coords.map(coord => ({ coordinates: coord }));
    return directions
      .getDirections({
        profile: 'walking',
        geometries: 'geojson',
        waypoints,
      })
      .send()
      .then(resp => {
        let { routes } = resp.body;
        let { geometry, duration, distance } = routes[0];
        return { route: geometry.coordinates, duration, distance };
      });
  },
  getStaticImage: ({
    plan,
    actual,
    center,
    width,
    height,
    zoom,
    opacity = 0.3,
  }) => {
    let request = staticClient.getStaticImage({
      ownerId: 'mapbox',
      styleId: 'light-v9',
      width,
      height,
      position: {
        coordinates: center,
        zoom,
      },
      overlays: [
        // {
        //   path: {
        //     coordinates: plan,
        //     strokeWidth: 4,
        //     strokeColor: '#4AA8D1',
        //     strokeOpacity: opacity,
        //   },
        // },
        {
          path: {
            coordinates: actual,
            strokeWidth: 10,
            strokeColor: '#FCEC5B',
            strokeOpacity: opacity,
          },
        },
      ],
    });

    return `${request.url()}?access_token=${MAPBOX_TOKEN}`;
  },
  getStreetName: coords => {
    return geocodingClient
      .reverseGeocode({
        query: coords,
        limit: 1,
      })
      .send()
      .then(resp => {
        if (resp.body.features.length > 0) {
          let { place_name: placeName, text } = resp.body.features[0];
          return {
            placeName,
            text,
          };
        }
        return coords;
      });
  },
};

const Routes = {
  create: (coordinates, duration) => {
    let data = {
      coordinates,
      duration,
    };
    return fetch(`${API_ROOT}/api/routes`, {
      method: 'POST',
      // mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    }).then(resp => resp.json());
  },
  get: (id, baseUrl) => {
    return fetch(`${API_ROOT}/api/routes/${id}`).then(resp => resp.json());
  },
  send: (email, url) => {
    let data = {
      email,
      url,
    };
    return fetch(`${API_ROOT}/api/routes/send`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

export { Mapbox, Routes };
