import React from 'react';
import ReactDOM from 'react-dom';
import { FirebaseAppProvider } from 'reactfire';
import App from './App';

const firebaseConfig = {
  apiKey: 'AIzaSyCd2bG9aZiins9fQITWSD5JUmi9KPxOypY',
  authDomain: 'paveyourway-ebaa9.firebaseapp.com',
  databaseURL: 'https://paveyourway-ebaa9.firebaseio.com',
  projectId: 'paveyourway-ebaa9',
  storageBucket: '',
  messagingSenderId: '30149087943',
  appId: '1:30149087943:web:81bf2a98a55cd1cb',
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  document.getElementById('root')
);
