const mapStyle = {
  border: '2px solid',
  borderRadius: '2px',
};

const geolocateStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: 10,
};

export { mapStyle, geolocateStyle };
