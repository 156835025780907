import React from 'react';
import { navigate } from '@reach/router';
import WebMercatorViewport from 'viewport-mercator-project';
import useInterval from '@use-it/interval';
import queryString from 'query-string';
import humanizeDuration from 'humanize-duration';
import Layout from '../components/layout';
import HeroHeader from '../components/header';
import Map from '../components/map-v2';
import Route from '../components/route-v2';
import Spinner from '../components/spinner';
import YellowPics from '../components/yellow-pics';
import useLocalStorage from '../hooks/use-local-storage';
import * as API from '../utils/api';

// IMPORTANT
// let coords = [long, lat];
const TRIP_STAGE = {
  SET_DEST: 0,
  SET_START: 1,
  PENDING_TRIP: 2,
  START_TRIP: 3,
  CANCLE_TRIP: 4,
  END_TRIP: 5,
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

function TripTracker({ count, duration }) {
  // duration - seconds
  return (
    <React.Fragment>
      <div className="trip-tracker level is-mobile">
        <div className="level-left">Steps: {count}</div>
        <div className="level-right">
          Time: {shortEnglishHumanizer(duration * 1000)}
        </div>
      </div>
      <style jsx="true">{`
        .trip-tracker {
          color: white;
          font-size: 1.25rem;
        }
      `}</style>
    </React.Fragment>
  );
}

function StartPage() {
  let myMapRef = React.useRef();
  let [stage, setStage] = React.useState('SET_DEST');
  let [isLoading, setIsLoading] = React.useState(true);
  let [tripDetail, setTripDetail] = React.useState(null);
  let [stepCount, setStepCount] = useLocalStorage('lost_step_count', 0);
  let [isYellowPicsVisible, setIsYellowPicsVisible] = React.useState(false); // TODO change to true
  let [lostDuration, setLostDuration] = useLocalStorage('lost_duration', 0);

  useInterval(() => {
    if (stage === 'START_TRIP') {
      setLostDuration(lostDuration + 1);
    }
  }, 1000);

  let handleStartTrip = () => {
    console.log('[trip] starting trip');
    myMapRef.current.handleStartTrip();
  };

  let handleEndTrip = () => {
    console.log('[trip] ending trip');

    let plan = JSON.parse(window.localStorage.getItem('lost_plan'));
    let actual = JSON.parse(window.localStorage.getItem('lost_path')).map(
      each => [each.longitude, each.latitude]
    );
    let durationActual = parseInt(window.localStorage.getItem('lost_duration'));
    let durationPlan = parseInt(
      window.localStorage.getItem('lost_duration_plan')
    );
    let stepsActual = parseInt(window.localStorage.getItem('lost_step_count'));
    let stepsPlan = parseInt(window.localStorage.getItem('lost_step_plan'));
    let origin = JSON.parse(window.localStorage.getItem('lost_start_coords'));
    let destination = JSON.parse(
      window.localStorage.getItem('lost_dest_coords')
    );

    let viewport = new WebMercatorViewport({
      width: 720,
      height: 1280,
    }).fitBounds([plan[0], plan[plan.length - 1]], {
      padding: 20,
      offset: [0, -50],
    });

    let imageUrl = API.Mapbox.getStaticImage({
      plan,
      actual,
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
      width: 720,
      height: 1280,
      opacity: 0.8,
    });

    console.log(`[trip] image URL: ${imageUrl}`);

    window.localStorage.removeItem('lost_path');
    window.localStorage.removeItem('lost_plan');
    window.localStorage.removeItem('lost_start');
    window.localStorage.removeItem('lost_end');
    window.localStorage.removeItem('lost_dest_coords');
    window.localStorage.removeItem('lost_start_coords');
    window.localStorage.removeItem('lost_step_count');
    setLostDuration(0);
    // window.localStorage.removeItem('lost_duration');
    window.localStorage.removeItem('lost_duration_plan');
    window.localStorage.removeItem('lost_step_plan');
    window.localStorage.removeItem('lost_distance_plan');

    let params = {
      duration: durationActual,
      durationMore: (
        ((durationActual - durationPlan) / durationPlan) *
        100
      ).toFixed(3),
      airMore: (durationActual / 60) * 8,
      stepsMore: stepsActual,
      origin,
      destination,
    };

    navigate(
      `/result?url=${encodeURIComponent(imageUrl)}&${queryString.stringify(
        params
      )}`
    );
  };

  let handleStepCount = steps => {
    setStepCount(stepCount + steps);
  };

  // React.useEffect(() => {
  //   if (stage === 'START_TRIP') {
  //     setInterval(() => {
  //       console.log('haha');
  //       setLostDuration(lostDuration + 1);
  //     }, 1000);
  //   }
  // }, [stage]);

  React.useEffect(() => {
    try {
      let startCoords = JSON.parse(
        window.localStorage.getItem('lost_start_coords')
      );
      let destCoords = JSON.parse(
        window.localStorage.getItem('lost_dest_coords')
      );
      let duration = parseInt(window.localStorage.getItem('lost_step_count'));
      if (startCoords && destCoords) {
        setTripDetail({
          start: startCoords,
          dest: destCoords,
          duration,
        });
        setStage('START_TRIP');
      }
    } catch (err) {}
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    // show pop up modal every 60s
    let timeoutId;

    let randomOpenYellowPic = () => {
      let rand = Math.random() * (600000 - 180000) + 180000;
      setTimeout(() => {
        setIsYellowPicsVisible(true);
        timeoutId = setTimeout(() => {
          setIsYellowPicsVisible(false);
        }, 60000);
        randomOpenYellowPic();
      }, rand);
    };

    randomOpenYellowPic();

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [stage]);

  return (
    <Layout>
      <YellowPics
        isOpen={isYellowPicsVisible && stage === 'START_TRIP'}
        onClose={() => setIsYellowPicsVisible(false)}
      />
      <section className="hero" style={{ maxHeight: '50vh' }}>
        <HeroHeader size={stage === 'START_TRIP' ? 'small' : 'large'} />
        {isLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className="hero-body">
              <div
                className="columns"
                style={{ height: '70vh', paddingTop: '24px' }}
              >
                {stage === 'START_TRIP' ? (
                  <Route
                    trip={tripDetail}
                    stage={stage}
                    setStage={setStage}
                    onStep={handleStepCount}
                  />
                ) : (
                  <Map
                    ref={myMapRef}
                    stage={stage}
                    setStage={setStage}
                    setTripDetail={setTripDetail}
                    setStepCount={setStepCount}
                  />
                )}
              </div>
            </div>
            <div
              className="hero-foot"
              style={{
                paddingBottom: 12,
                paddingTop: 6,
                paddingLeft: 24,
                paddingRight: 24,
              }}
            >
              <div
                className="container"
                style={{
                  textAlign: 'center',
                }}
              >
                {stage === 'START_TRIP' ? (
                  <React.Fragment>
                    <TripTracker count={stepCount} duration={lostDuration} />
                    <button
                      className="end-trip-btn button"
                      onClick={handleEndTrip}
                    >
                      End Trip
                    </button>
                  </React.Fragment>
                ) : (
                  <button
                    className="trip-btn button"
                    disabled={stage === 'PENDING_TRIP' ? false : true}
                    onClick={handleStartTrip}
                  >
                    Start Trip
                  </button>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </section>
      <style jsx="true">{`
        .end-trip-btn {
          background: rgba(255, 255, 255, 1);
          border-radius: 19px;
          -webkit-border-radius: 19px;
          font-family: Source Serif Variable;
          font-weight: bold;
          font-size: 1.25rem;
          color: rgb(91, 160, 181);
          opacity: 0.9;
        }

        .trip-btn {
          background: rgba(255, 255, 255, 1);
          border-radius: 19px;
          -webkit-border-radius: 19px;
          font-family: Source Serif Variable;
          font-weight: bold;
          font-size: 1.25rem;
          letter-spacing: 2px;
          color: rgb(91, 160, 181);
          opacity: 0.9;
        }

        .hero-body {
          padding: 0.5rem 1.5rem 0rem 1.5rem;
        }

        .hero-foot {
          padding: 6px 24px 12px;
          margin-top: -24px;
        }
      `}</style>
    </Layout>
  );
}

export default StartPage;
