function sleep(ms) {
  return new Promise(function(resolve) {
    setTimeout(resolve, ms);
  });
}

const initCoords = {
  latitude: 51.918706, // 53.53826751667482,
  longitude: 4.488695, //-113.50662270510246,
};

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

export { sleep, initCoords, MAPBOX_TOKEN };
